import { memo, ComponentProps, useCallback, useRef, useState, useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import VideoPlayer from "@megapolis/react-video-player";
import { Button } from "@megapolis/uikit";
import { Icons } from "@megapolis/uikit/icons";
import { useToken } from "shared/hooks";
import * as Env from "env-data";
import { createPortal } from "react-dom";

const DEFAULT_URL: string = Env.REACT_APP_API_CAMERA_SERVICE;

type VideoPlayerProps = ComponentProps<typeof VideoPlayer>;

export type CameraVideoPlayerProps = Omit<VideoPlayerProps, "token" | "url"> & {
    url?: string;
    className?: string;
};

const UpIcon = <Icons.Monochrome.ChevronUpL />;
const DownIcon = <Icons.Monochrome.ChevronDownL />;
const RightIcon = <Icons.Monochrome.ChevronRightL />;
const LeftIcon = <Icons.Monochrome.ChevronLeftL />;

const moveValue = 500;

const moveCamera = (id: number | string, token: string, pan: number, tilt: number) =>
    fetch(`${Env.REACT_APP_API_CAMERA_SERVICE}/camera/move?id=${id}&pan=${pan}&tilt=${tilt}`, {
        method: "POST",
        headers: {
            authorization: `Bearer ${token}`,
        },
    });

const createHandlers = (id: number | string, token: string) => {
    const mover = (pan: number, tilt: number) => () => moveCamera(id, token, pan, tilt);
    return {
        moveLeft: mover(-moveValue, 0),
        moveRight: mover(moveValue, 0),
        moveUp: mover(0, moveValue),
        moveDown: mover(0, -moveValue),
    };
};

const CameraVideoPlayerComponent = (props: CameraVideoPlayerProps) => {
    const { url = DEFAULT_URL, id, autoplay = false, className, ...rest } = props;
    const [playerEl, setPlayerEl] = useState<HTMLElement | null>(null);
    const token = useToken();

    const container = useRef<HTMLDivElement | null>(null);

    const isFullscreen = useIsFullscreen();

    const handlers = useMemo(() => createHandlers(id, token), [id, token]);

    const getToken = useCallback(() => token, [token]);

    useEffect(() => {
        if (!isFullscreen) return;

        const handler = (e: KeyboardEvent) => {
            if (e.code === "ArrowUp") return handlers.moveUp();
            if (e.code === "ArrowDown") return handlers.moveDown();
            if (e.code === "ArrowLeft") return handlers.moveLeft();
            if (e.code === "ArrowRight") return handlers.moveRight();
        };

        window.addEventListener("keyup", handler);
        return () => {
            window.removeEventListener("keyup", handler);
        };
    }, [isFullscreen, handlers]);

    useEffect(() => {
        if (!container.current) return;
        const el = container.current.children[0] as HTMLElement;
        setPlayerEl(el);
    }, []);

    if (!url) {
        console.error(`url property does not provided for camera ${id}`);
        return null;
    }

    return (
        <StyledPlayer className={className} ref={container}>
            <VideoPlayer id={id} url={url} token={getToken} autoplay={autoplay} {...rest} />
            {playerEl &&
                isFullscreen &&
                createPortal(
                    <PTZButtons>
                        <PTZButton
                            content={Button.Content.Icon}
                            priority="tertiary"
                            icon={UpIcon}
                            size="m"
                            onClick={handlers.moveUp}
                        />
                        <Flex>
                            <PTZButton
                                content={Button.Content.Icon}
                                priority="tertiary"
                                icon={LeftIcon}
                                size="m"
                                onClick={handlers.moveLeft}
                            />
                            <PTZButton
                                content={Button.Content.Icon}
                                priority="tertiary"
                                icon={RightIcon}
                                size="m"
                                onClick={handlers.moveRight}
                            />
                        </Flex>
                        <PTZButton
                            content={Button.Content.Icon}
                            priority="tertiary"
                            icon={DownIcon}
                            size="m"
                            onClick={handlers.moveDown}
                        />
                    </PTZButtons>,
                    playerEl
                )}
        </StyledPlayer>
    );
};

const useIsFullscreen = () => {
    const [isFullscreen, setIsFullscreen] = useState(() => !!document.fullscreenElement);

    useEffect(() => {
        const handler = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };
        window.addEventListener("fullscreenchange", handler);
        return () => {
            window.removeEventListener("fullscreenchange", handler);
        };
    }, []);

    return isFullscreen;
};

export const CameraVideoPlayer = memo(CameraVideoPlayerComponent);

const PTZButtons = styled.div`
    visibility: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 0px;
    bottom: 50px;

    svg {
        fill: #fff;
        width: 18px;
        height: 18px;
    }
`;

const PTZButton = styled(Button)`
    background-color: #ffffff40;
`;

const Flex = styled.div`
    display: flex;
    gap: 40px;
`;

const StyledPlayer = styled.div`
    position: relative;
    :hover {
        ${PTZButtons} {
            visibility: visible;
        }
    }
`;
